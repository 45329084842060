import { Col, Row } from 'antd';
import AuthValidate from '@components/authValidate';
import Layout from '@components/layout/VerticalLayout';
import { useSelector } from 'react-redux';
import { RootState } from '@store';

const Contact = () => {
  const userData = useSelector((state: RootState) => state?.Session?.user);
  return (
    <Layout>
      <AuthValidate
        // notAuthenticatedRules={notAuthenticatedRules}
        requiredAutentication
      >
        <Row gutter={[32, 0]}>
          <Col span={24}>
            <h3>Bem vindo de volta, {userData?.nickname || userData?.name} 👋</h3>

            <p className="da-p1-body da-mb-0">Aqui estão as informações sintetizadas para você</p>
          </Col>

          <Col span={24} className="da-mt-32">
            <Row gutter={[32, 0]}>
              <Col flex="1" className="da-overflow-hidden">
                <Row gutter={[32, 32]}>
                  <Col span={24}></Col>
                </Row>
              </Col>

              <Col className="da-analytics-col-2"></Col>
            </Row>
          </Col>
        </Row>
      </AuthValidate>
    </Layout>
  );
};

export default Contact;
