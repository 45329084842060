import { Spin, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, RootState } from '@store';
import { useDispatch } from 'react-redux';
import { redirectToLogin } from '@helpers/router';
// import { PublicUserData } from '@api/feature-module/users/user.helpers';
import { Status } from '@models/Session/types.d';

export type AuthValidateRule = (sessionStatus: Status) => boolean | React.ReactElement;
export type NotAuthValidateRule = () => boolean | React.ReactElement;

export type AuthValidateRules = AuthValidateRule[];
export type NotAuthValidateRules = NotAuthValidateRule[];

type Props = {
  children?: JSX.Element;
  requiredAutentication?: boolean;
  redirectTo?: string;
  loadingComponent?: JSX.Element;
  notAuthenticatedRules?: NotAuthValidateRules;
  authenticatedRules?: AuthValidateRules;
};

/**
 * É utilizado, numa situação que o usuário está autenticado e precisa ser verificado seus dados de acesso
 *
 */
const AuthValidate = ({
  children,
  requiredAutentication,
  loadingComponent = <Spin>Aguarde</Spin>,
  notAuthenticatedRules = [],
  authenticatedRules = [],
}: Props): JSX.Element => {
  //   const dispatch = useDispatch<Dispatch>();
  const SessionStatus = useSelector((state: RootState) => state?.Session?.status);
  //   const userData = useSelector((state: RootState) => state?.Session?.user);
  //   const [userDataInSession, setUserDataInSession] = useState<null | 'inprogress' | 'done'>(null);

  // se usuário está autenticado
  if (SessionStatus?.id) {
    // se tem regras para validar o usuário quando está autenticado
    const validTests = authenticatedRules.length ? authenticatedRules.map((rule) => rule(SessionStatus)) : null;

    // se teve algum teste
    if (validTests?.length) {
      // se todos os testes são booleanos
      if (validTests.every((test) => typeof test === 'boolean')) {
        // se ao menos 1 é falso
        if (validTests.some((test) => test === false)) {
          // apresenta tela de proibído acesso
          return <>O acesso a este recurso não foi permitido.</>;
        }
      }
      // se algum dos testes não for boolean,
      // então verificar se algum deles pode ser um componente para então o renderizar
      else {
        // encontrar o primeiro que retorna um componente
        const comp = validTests.find((test) => {
          return React.isValidElement(test);
        });

        // se encontrar, renderiza ele e não carrega o componente filho
        if (comp) {
          return <>{comp}</>;
        }
      }
    }
  }
  // se usuário não está autenticado
  else {
    // e é obrigatório que ele esteja autenticado
    if (requiredAutentication) {
      // se tem regras para validar o usuário quando não está autenticado
      const validTests = notAuthenticatedRules.length ? notAuthenticatedRules.map((rule) => rule()) : null;

      // se teve algum teste
      if (validTests?.length) {
        // se todos os testes são booleanos
        if (validTests.every((test) => typeof test === 'boolean')) {
          // se ao menos 1 é falso
          if (validTests.some((test) => test === false)) {
            // apresenta tela de proibído acesso
            return <>O acesso a este recurso não foi permitido.</>;
          }
        }
        // se algum dos testes não for boolean,
        // então verificar se algum deles pode ser um componente para então o renderizar
        else {
          // encontrar o primeiro que retorna um componente
          const comp = validTests.find((test) => {
            return React.isValidElement(test);
          });

          // se encontrar, renderiza ele e não carrega o componente filho
          if (comp) {
            return <>{comp}</>;
          }
        }
      }

      redirectToLogin();
      // apresenta componente de loading, entendendo que o usuário será autenticado ou está aguardando autenticação
      return loadingComponent;
    }
  }

  return <>{children}</>;
};

export default AuthValidate;
